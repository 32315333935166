.roles-page {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    &__table {
        position: relative;
    }

    &__bar {
        display: flex;
        flex-direction: row;
        margin: 24px 0;
    }

    &__add-role {
        margin-left: auto;
    }
}
