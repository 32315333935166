.add-user-access-form {
    display: flex;
    flex-direction: column;

    div.oui-dropdown-children,
    ul {
        li {
            min-width: 315px;
        }
    }

    &__product-dropdown {
        & > button {
            width: 50%;
            margin-bottom: var(--axiom-grid-margin-small);
            text-align: left;
            &:hover:not([disabled]) {
                background-color: #fff;
            }
        }
    }

    &__selection-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    // Some dumb override for the dropdown's width b/c axiom is bad.
    &__selection-row-dropdown {
        margin-right: var(--axiom-grid-margin-small);
        width: 100%;
        min-width: 0;
        button {
            width: 100%;
            min-width: 250px;
            text-align: start;
            &:hover:not([disabled]) {
                background-color: #fff;
            }
        }
        button + div {
            min-width: 250px;
        }
        ul {
            li {
                min-width: 250px;
            }
        }

        &--small {
            button {
                width: 180px;
                min-width: 180px;
            }
        }
    }

    &__selection-row-item {
        width: 33%;
        &--small {
            width: 25%;
        }
    }

    &__actions {
        width: 80px;
        min-width: 80px;
        display: flex;
        margin-top: 28px; // height of the dropdown labels + 2px so dropdown aligns with buttons
        & > button {
            max-width: 40px !important;
        }
    }
}
